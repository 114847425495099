.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    color: #2F2F37;
}

.container::after {
    content: "";
    width: 100%;
    border-bottom: 3px solid #17171C;
}

.container::before {
    content: "";
    width: 100%;
    height: 12px;
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 1px, #FAFAFA 1px, #FAFAFA calc(100% - 1px), rgba(15,15,15,1) 100%, rgba(0,0,0,1) 100%);
    position: absolute;
    bottom: 3px
}

.pageHeading {
    font-size: clamp(3rem, 9vw + 1rem, 8.438rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 3rem 0;
}

.pageHeadingSubtitle {
    width: 100%;
    text-align: center;
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: 0.8em;
    text-transform: uppercase;
    padding-top: 1em;
    padding-bottom: 1em;
    margin-top: 1em;
    border-top: 3px solid #17171C;
    border-bottom: 1px solid #17171C;
    font-family: 'Roboto';
    font-stretch: 25%;
    font-variation-settings: 'opsz' 31;
}

@media screen and (min-width: 48rem) {
    .pageHeadingSubtitle {
        margin-top: 2.063em;
        letter-spacing: 1ch;
        padding-top: 1.313em;
        padding-bottom: 1.313em;
    }
}

.intro {
    max-width: 720px;
    font-weight: 500;
    font-size: clamp(1rem, 2vw + 1rem, 2rem);
    font-stretch: 76%;
    font-family: 'Roboto';
    font-variation-settings: 'opsz' 144;
    text-align: center;
    align-self: center;
}

.creator {
    font-size: 0.750rem;
    text-transform: uppercase;
    font-stretch: 25%;
    font-weight: 400;
    letter-spacing: 0.3ch;
    font-variation-settings: 'grad' -200;
    align-self: center;
}

.volume {
    display: flex;
    flex-direction: column;
    align-self: end;
    text-transform: uppercase;
}

.volumeText {
    font-weight: 600;
    font-size: 0.688em;
    font-stretch: 100%;
}

.volumeNum {
    font-size: 1.563rem;
    font-stretch: 25%;
    font-weight: 555;
    font-variation-settings: 'opsz' 65;
}