.footerContainer {
    width: 100%;
}

.footerBlock {
    background: #E9F3F5;
    border-bottom: 3px solid #17171C;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 208px;
}

.logo {
    width: 80px;
    height: 80px;
}

.footerLinks {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.nav {
    width: 100%;
    border-bottom: 1px solid #17171C;
    margin-bottom: 2rem;
}
  
.navList {
    list-style: none;
    padding: 0;
    margin: 2rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.25rem;
}

@media screen and (min-width: 48rem) {
    .navList {
        display: flex;
        justify-content: center;
        height: 56px;
        margin: 0;

    }
}

.navItem {
    display: flex;
    align-items: center; 
}

.navLink {
    font-size: 0.875rem;
    font-weight: 500;
    margin: 0 1.429rem;
    text-decoration: none;
}

.navLink,
.navLink:visited {
    color: #2F2F37;
}

.navLink:hover {
    color: #C22E84;
}

.footnote {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    height: 15.438rem;
}

.social {
    display: flex;
    flex-direction: row;
    width: 200px;
    align-items: center;
    justify-content: space-between;
}

.creator {
    font-size: 0.750rem;
    text-transform: uppercase;
    font-stretch: 25%;
    font-weight: 500;
    letter-spacing: 0.3ch;
    font-variation-settings: 'grad' -200;
    align-self: center;
}